@import "~@dpdgroupuk/mydpd-ui/styles/bootstrap";

.submit {
  background: linear-gradient(
    269.06deg,
    #9c01ff 0.67%,
    #6301ff 99.19%
  ) !important;
  border-color: #9c01ff !important;
  border-radius: 5px !important;
}
