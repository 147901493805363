@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.tableContainer {
  overflow-y: auto;
  max-height: 250px;
}

.table {
  margin: 0;

  tr {
    td {
      display: flex;
      min-height: 32px;
      padding: 0;
      word-break: break-all;
      overflow-x: auto;
      text-transform: none;
    }
  }

  tr:first-child td {
    border: none;
  }
}

.cardTitle {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  padding-right: 0;
}

.btn {
  margin-top: 1px;
  align-items: start;
}
