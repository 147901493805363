@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.templateBtn {
  margin: 2px 16px 0 0;
}

.aiButton {
  border-radius: 13px !important;
  border-color: #6100ff !important;
  margin-top: 2px;
  background: linear-gradient(90deg, #6100ff 0%, #9e00ff 100%);

  > * {
    transform: translateY(1px);
  }
}

.aqaFileInput {
  display: none;
}
