@import "~@dpdgroupuk/mydpd-ui/styles/bootstrap";
@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.tableContainer {
  height: 360px;

  .translationGroup {
    @include media-breakpoint-down(md) {
      min-width: 142px;
      max-width: 142px;
    }

    border: none;
    min-width: 182px;
    max-width: 182px;
    height: 100%;
    margin-bottom: 0;
    margin-left: -10px;
  }

  thead {
    transform: translateY(-1px);
    z-index: $zindex-mydpd-input;

    tr th {
      background: #e5cef4;
    }
  }
}

.ghostingRow {
  background: $gray_alt_bg_light !important;
}

.translationInput {
  font-size: 12px !important;

  &:target,
  &:active:not(:disabled),
  &.active:not(:disabled),
  &:focus:not(:disabled),
  &.focus:not(:disabled) {
    outline: 1px solid $blue_1; // Added to avoid confusion when translation dropdown is in focus
  }
}

.translationDropdown {
  font-size: 12px;
  width: max-content !important;
  position: fixed !important;

  // Hide dropdown menu when input is out of view
  &[data-popper-reference-hidden="true"] {
    visibility: hidden;
  }
}

.translationOption {
  width: 100%;
}

.availableTableContainer {
  height: 360px;

  thead {
    transform: translateY(-1px);
  }
}

.actionSectionShiftStart {
  transform: translateX(-2 * $grid-gutter-half-width);
}

.actionSectionShiftEnd {
  transform: translateX(2 * $grid-gutter-half-width);
}
