@import "~@dpdgroupuk/mydpd-ui/styles/bootstrap";
@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.col {
  display: flex;
  align-items: stretch;

  > div {
    width: 100%;
  }
}

.column {
  @include media-breakpoint-down(lg) {
    margin-bottom: 24px;
  }
}
