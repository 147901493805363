@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.col {
  display: flex;
  align-items: stretch;

  > div {
    width: 100%;
  }
}

.actionButtons {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $brown;
  opacity: 0.94;

  button,
  a {
    color: $black;
  }
}

.applyBtn {
  background: linear-gradient(269.06deg, #9c01ff 0.67%, #6301ff 99.19%);
  border-color: #9c01ff !important;
  border-radius: 5px !important;
}
