@import "~@dpdgroupuk/mydpd-ui/styles/bootstrap";

.modalContainer {
  @include media-breakpoint-down(lg) {
    min-width: 80vw;
  }
  min-width: 1165px;
}

.tableContainer {
  height: 360px;

  thead {
    transform: translateY(-1px);
  }
}

.modalContent {
  padding: 0 !important;
}

.importedFields {
  @include media-breakpoint-down(lg) {
    margin-bottom: 24px;
  }
}
